<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title>
          Fuentes de emisión
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </v-card-title>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
          <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idfuente_emision)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          No hay información disponible
        </template>
        <template v-slot:no-results>
          No se obtuvieron resultados
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nueva fuente de emisión</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="forms.new.alcance" :items="catalogues.alcances" label="Alcance" :rules="rules.alcance" :disabled="loading" required></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar fuente de emisión</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="forms.update.alcance" :items="catalogues.alcances" label="Alcance" :rules="rules.alcance" :disabled="loading" required></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la fuente de emisión?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'

export default {
  name: 'emissionSource',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Alcance', align: 'left', sortable: false, value: 'alcance' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' },
    ],
    items: [],
    selected: [],
    deleted: null,
    catalogues: {
      alcances: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 }
      ]
    },
    forms: {
      new: {
        nombre: '',
        alcance: ''
      },
      update: {
        idfuente_emision: '',
        nombre: '',
        alcance: ''
      }
    },
    rules: {
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      alcance: [
        v => !!v || 'El campo es requerido'
      ]
    },
    dialogs: {
      new: false,
      update: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.loading = true
      this.axios.get(services.routes.emissionSource)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          this.forms.new[key] = ''
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          this.forms.update[key] = ''
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        this.axios.post(services.routes.emissionSource, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Fuente de emisión creada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data) {
      this.forms.update = {
        idfuente_emision: data.idfuente_emision,
        nombre: data.nombre,
        alcance: data.alcance
      }
      this.dialogs.update = true
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.emissionSource, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Fuente de emisión actualizada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        let id = this.deleted
        this.axios.delete(services.routes.emissionSource, { data: { data: { idfuente_emision: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Fuente de emisión eliminada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    }
  }
}
</script>

<style scoped>
</style>
